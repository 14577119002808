/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
strong,
input,
label,
button,
li,
a {
  font-family: "Poppins", sans-serif;
}
 
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 21px;

  h4 {
    text-align: left;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600;
    color: #103158;
    margin: 0;
    padding: 0 10px;
    white-space: nowrap;
  }

  .ion-icon {
    color: #103158;
    width: 18px;
  }

  div {
    flex-grow: 1;
    height: 1px;
    border: 1px solid #d0d8e5;
  }
} 

.custom-loading-class { 
  color: #103158;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

input,
  select {
    height: 60px;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d0d8e5;
    border-radius: 20px;
    padding: 0 0 0 20px;
    font-size: 13px;
    -webkit-appearance: none;
} 

.error-message {
  color: #ef6a6f;
  font-size: 12px;
  font-weight: 400;
}